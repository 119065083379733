
import { defineComponent, ref, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import Tile from '@/components/atom/Tile.vue'
import { useRoute } from 'vue-router'
import moment from 'moment'
import authApi from '@/api/Authenticated'
import NameIcon from '@/components/atom/NameIcon.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import { projectTypes } from '@shared/*'
import CustomTable from '@/components/atom/CustomTable.vue'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    Tile,
    NameIcon,
    CustomTable
  },
  setup() {
    type BlockType = {
      id: number
      title: string
      desc: {
        name: string | null
        email?: string
        amount?: string | number
        country?: string
        phone?: string
        desc?: string
        date?: string
      }
    }

    type ProjectViewType = {
      id: number
      heading: string
      totalFields: number
      info: BlockType[]
    }
    const route = useRoute()
    const projectId = route.params.id
    const studioId = route.params.studioId
    const projectDetails = ref<projectTypes.SingleProjectResponse | null>(null)
    const commercialDetails = ref<BlockType[]>([])
    const scopeDetails = ref<BlockType[]>([])
    const projectDetailDisplay = ref<ProjectViewType[]>()
    const legalDetails = ref<BlockType[]>([])

    const iconText = function (name: string) {
      return name.match(/\b(\w)/g)?.join('')
    }
    function filterDate(date: any) {
      return date ? moment(date).format('DD/MM/YYYY') : '-'
    }
    onMounted(async () => {
      projectDetails.value = await authApi.getProjectDetails(+projectId)

      if (projectDetails.value) {
        commercialDetails.value.push(
          {
            id: 31,
            title: 'Fees & charges',
            desc: {
              name: projectDetails.value.type,
              amount: projectDetails.value.totalValue !== null ? intlFormat(projectDetails.value.totalValue) : ''
            }
          },
          {
            id: 32,
            title: 'VAT/GST',
            desc: {
              name: projectDetails.value.vatPercentage + '%',
              amount: ''
            }
          },
          {
            id: 33,
            title: 'Payment terms',
            desc: {
              name: projectDetails.value.paymentTermsDays.toString(),
              amount: ''
            }
          },
          {
            id: 34,
            title: 'Expenses',
            desc: {
              name:
                projectDetails.value.expensesIncluded === true ? 'Included in the fees' : 'Not included in the fees',
              amount: ''
            }
          }
        )

        scopeDetails.value.push(
          {
            id: 20,
            title: 'Overview',
            desc: {
              name: projectDetails.value.overview
            }
          },
          {
            id: 21,
            title: 'Project background',
            desc: {
              name: projectDetails.value.background
            }
          },
          {
            id: 22,
            title: 'Services',
            desc: {
              name: projectDetails.value.services
            }
          },
          {
            id: 23,
            title: 'Approach',
            desc: {
              name: projectDetails.value.approach
            }
          },
          {
            id: 24,
            title: 'Restrictions or dependencies requirements',
            desc: { name: projectDetails.value.restrictions }
          }
        )
        legalDetails.value.push(
          {
            id: 41,
            title: 'Contract management',
            desc: {
              name: projectDetails.value.contract
            }
          },
          {
            id: 42,
            title: 'Special requests or additional terms',
            desc: {
              name: projectDetails.value.additional
            }
          }
        )

        if (projectDetails.value.invoices.length > 0) {
          let index = 0
          projectDetails.value.invoices.map((invoice: any) => {
            index += 1
            commercialDetails.value.push({
              id: 37,
              title: 'Invoice ' + index,
              desc: {
                name: 'Date ' + filterDate(invoice.date),
                amount: intlFormat(
                  invoice.invoiceItems
                    .map((item: any) => item.unitsCount * item.costPerUnit)
                    .reduce((a: any, b: any) => a + b, 0)
                )
              }
            })
          })
        }

        if (projectDetails.value.milestones.length > 0) {
          scopeDetails.value.push({
            id: 28,
            title: 'Deliverables',
            desc: {
              name: ''
            }
          })
        }
        if (projectDetails.value.staffing.length > 0) {
          scopeDetails.value.push({
            id: 29,
            title: 'Staffing ',
            desc: { name: '' }
          })
        }

        projectDetailDisplay.value = [
          {
            id: 1,
            heading: 'Project details',
            totalFields: 9,
            info: [
              {
                id: 11,
                title: 'Project ID',
                desc: {
                  name: projectDetails.value.id.toString()
                }
              },
              {
                id: 12,
                title: 'Project name',
                desc: {
                  name: projectDetails.value.name
                }
              },
              {
                id: 13,
                title: 'Client name',
                desc: {
                  name: projectDetails.value.client.name
                }
              },
              {
                id: 14,
                title: 'Client contact',
                desc: {
                  name: projectDetails.value.client.leadContactName,
                  email:
                    projectDetails.value.client.leadContactEmail !== null
                      ? projectDetails.value.client.leadContactEmail
                      : '',
                  phone:
                    projectDetails.value.client.leadContactPhone !== null
                      ? projectDetails.value.client.leadContactPhone
                      : ''
                }
              },
              {
                id: 15,
                title: 'Project lead',
                desc: {
                  name: projectDetails.value.projectLeadName ?? '-',
                  email: projectDetails.value.projectLeadEmail ?? '-'
                }
              },
              {
                id: 16,
                title: 'Phase',
                desc: {
                  name: projectDetails.value.phase
                }
              },
              {
                id: 17,
                title: 'Start date',
                desc: {
                  name: filterDate(projectDetails.value.startDate)
                }
              },
              {
                id: 18,
                title: 'Duration',
                desc: {
                  name:
                    projectDetails.value.numberOfWeeks !== null && projectDetails.value.numberOfWeeks > 1
                      ? projectDetails.value.numberOfWeeks + ' weeks'
                      : projectDetails.value.numberOfWeeks + ' week'
                }
              },
              {
                id: 19,
                title: 'Project manager',
                desc: {
                  name: projectDetails.value.owner.fullName
                }
              },
              ...projectDetails.value.projectCustomFields
                .filter((e: any) => e.type === 'ProjectDetails')
                .map((e: any, index: number) => {
                  return {
                    id: 100 + index,
                    title: e.heading ?? '',
                    desc: {
                      name: e.description ? e.description : ''
                    }
                  }
                })
            ].filter((x) => x.desc.name !== null)
          },
          {
            id: 2,
            heading: 'Scope & deliverables',
            totalFields: 6,
            info: [
              ...scopeDetails.value.filter((x) => x.desc.name !== null),
              ...projectDetails.value.projectCustomFields
                .filter((e: any) => e.type === 'ScopeDetails')
                .map((e: any, index: number) => {
                  return {
                    id: 200 + index,
                    title: e.heading ?? '',
                    desc: {
                      name: e.description ? e.description : ''
                    }
                  }
                })
            ]
          },
          {
            id: 3,
            heading: 'Commercial details',
            totalFields:
              5 + Number(projectDetails.value.invoices.length === 0 ? 1 : projectDetails.value.invoices.length),
            info: [
              ...commercialDetails.value.filter((x) => x.desc.name !== null),
              {
                id: 36,
                title: 'Purchase order number',
                desc: {
                  name: projectDetails.value.purchaseOrder ?? '-'
                }
              },
              ...projectDetails.value.projectCustomFields
                .filter((e: any) => e.type === 'CommercialDetails')
                .map((e: any, index: number) => {
                  return {
                    id: 300 + index,
                    title: e.heading ?? '',
                    desc: {
                      name: e.description ? e.description : ''
                    }
                  }
                })
            ]
          },
          {
            id: 4,
            heading: 'Legal details',
            totalFields: 2,
            info: legalDetails.value.filter((x) => x.desc.name !== null)
          }
        ]
      }
    })

    return { projectDetailDisplay, projectId, iconText, studioId, projectDetails, filterDate }
  }
})
