<template>
  <div class="timesheet">
    <ul class="weeks mobile-hide">
      <li v-for="week in weekArray" :key="week">
        <div :class="'week ' + week.selected" @click="getCurrentWeek(week.weekNumber)">
          <span class="week__number">week {{ week.weekNumber }}</span>
          <span :class="week.iconStatus === true ? 'verified' : 'warning'"></span>
        </div>
      </li>
    </ul>
    <div class="current-week" v-if="activeWeek.weekNumber">
      <swiper
        @activeIndexChange="activeIndexChange"
        class="desktop-hide"
        :slides-per-view="1"
        :space-between="100"
        :navigation="true"
        @swiper="onSwiper($event, activeWeek.weekNumber - 1)"
      >
        <swiper-slide v-for="week of weekArray" :key="week" class="slider-week">
          <div>
            <h4>week {{ week.weekNumber }}</h4>
            <img class="error-img" src="@/assets/icons/error-mustered.svg" v-if="week.iconStatus == false" />
            <img class="error-img" src="@/assets/icons/tick-green.svg" v-if="week.iconStatus == true" />
            <span :class="week.iconStatus === true ? 'verified' : 'warning'"></span>
            <p>Starting {{ week.weekDate }}</p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="mobile-hide">
        <h4>
          Week {{ activeWeek.weekNumber }}
          <img class="error-img" src="@/assets/icons/error-mustered.svg" v-if="activeWeek.iconStatus == false" />
          <img class="error-img" src="@/assets/icons/tick-green.svg" v-if="activeWeek.iconStatus == true" />
        </h4>
        <p>Starting {{ activeWeek.weekDate }}</p>
      </div>
      <div class="button">
        <CustomButton
          @onClick="addWorkLog"
          :label="'Confirm week ' + activeWeek.weekNumber + ' timesheet'"
          color="blue"
        />
      </div>
    </div>
    <div class="timesheet-table">
      <div class="timesheet-table-item">
        <TableTile>
          <template #tileContent>
            <div class="timesheet-member">
              <CustomButton @onClick="addField" label="Add a time entry" color="grey" size="small" iconClass="plus" />
            </div>
          </template>
        </TableTile>
        <TableTile v-for="(workLog, index) of workLogs" :key="workLog.id">
          <template #tileContent>
            <div class="timesheet-member">
              <!-- Service Role -->
              <div @click="openServiceModal(index)" class="tile-content">
                <p class="tile-content__title name-wrapper">
                  {{ workLog.service.name ? workLog.service.name : 'Service Role' }}
                </p>
                <div class="tile-icon">
                  <img src="@/assets/icons/arrow-down-primary.svg" />
                </div>
                <div class="dropdown-wrapper" v-if="serviceDropDownValue === index">
                  <customListDown
                    v-click-away="serviceClickAway"
                    @onClick="selectService($event, index)"
                    :values="projectServices"
                  />
                </div>
              </div>

              <!-- User Section -->
              <div @click="openTeamModel(index, workLog)" class="tile-content member-wrapper">
                <NameIcon
                  :text="
                    workLog.user?.fullName
                      ? workLog.user?.fullName
                      : workLog.subcontractor?.name
                      ? workLog.subcontractor?.name
                      : 'Team Member'
                  "
                />
                <p class="tile-content__title name-wrapper">
                  {{
                    workLog.user?.fullName
                      ? workLog.user?.fullName
                      : workLog.subcontractor?.name
                      ? workLog.subcontractor?.name
                      : 'Team Member'
                  }}
                </p>

                <div class="tile-icon">
                  <img src="@/assets/icons/arrow-down-primary.svg" />
                </div>

                <div class="dropdown-wrapper" v-if="teamsDropDownValue === index">
                  <customListDown
                    v-click-away="teamMembersClickAway"
                    @onClick="selectProjectTeam($event, index)"
                    :values="projectTeamMembers"
                  />
                </div>
              </div>
              <div class="tile-row">
                <div class="tile-content datepicker-tooltip">
                  <Tooltip
                    tooltipTitle="Select a date"
                    tooltipText="Select Mon for total week, or a specific day."
                    fieldType="timesheet"
                  >
                    <DatePickerModelValue
                      v-if="workLog.date"
                      v-model="workLog.date"
                      :disabled="workLog.id !== 0"
                      :border="false"
                      :minDate="new Date(activeWeek.weekDate)"
                      :maxDate="getMaxDate"
                    ></DatePickerModelValue>
                  </Tooltip>
                </div>

                <div @click="openHoursModel(index)" class="tile-content">
                  <Tooltip
                    tooltipTitle="Hours worked"
                    tooltipText="Time worked in hours. For total week or for a combination of days."
                    fieldType="timesheet"
                  >
                    <div class="tile-icon">
                      <img src="@/assets/icons/time.svg" />
                    </div>
                  </Tooltip>
                  <p class="tile-content__title">{{ workLog.hoursWorked }}</p>
                  <div class="dropdown-wrapper" v-if="hoursDropDownValue === index">
                    <customListDown
                      v-click-away="hoursClickAway"
                      @onClick="selectHours($event, index)"
                      :values="daysArray"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #menu v-if="workLog.id">
            <ul>
              <li @click="deleteWorkLog(workLog.id)">Delete</li>
            </ul>
          </template>
        </TableTile>
      </div>
      <div class="timesheet-table-item">
        <CustomTable>
          <template #heading>
            <th>Role Totals</th>
            <th>Hours Budgeted</th>
            <th>Hours Spend</th>
            <th></th>
          </template>
          <template #content>
            <tr v-for="item of projectSpend" :key="item.id">
              <td>{{ item.service.name }}</td>
              <td>
                {{ Math.round((item.estimatedHours / weekArray.length + Number.EPSILON) * 100) / 100 }}
              </td>
              <td>{{ item.hoursWorked ? item.hoursWorked : 0 }}</td>
              <td
                :class="
                  Math.sign(calculateSpend(item.estimatedHours / weekArray.length, item.hoursWorked)) == -1
                    ? 'pink-text'
                    : 'green-text'
                "
              >
                {{
                  item.hoursWorked
                    ? calculateSpend(item.estimatedHours / weekArray.length, item.hoursWorked)
                    : item.estimatedHours / weekArray.length
                }}
              </td>
            </tr>
            <tr>
              <td>Totals</td>
              <td>{{ totalBudget }}</td>
              <td>{{ totalSpend }}</td>
              <td :class="Math.sign(calculateSpend(totalBudget, totalSpend)) == -1 ? 'pink-text' : 'green-text'">
                {{ calculateSpend(totalBudget, totalSpend) }}
              </td>
            </tr>
          </template>
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableTile from '@/components/templates/TableTile.vue'
import CustomTable from '@/components/templates/CustomTable.vue'
import NameIcon from '@/components/atom/NameIcon.vue'
import customListDown from '@/components/atom/CustomListDown.vue'
import { directive } from 'vue3-click-away'
import DatePickerModelValue from '@/components/atom/DatePickerModelValue.vue'
import authApi from '@/api/Authenticated'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { ActionTypes as MessageActions } from '@/store/messages/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import { projectTypes } from '@shared/'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper/core'
import moment from 'moment'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.min.css'
import { MutationTypes } from '@/store/messages/mutations'
import { intlFormat } from '@/utils/currencyFunctions'
import Tooltip from '@/components/atom/Tooltip.vue'

// install Swiper modules
SwiperCore.use([Navigation])
export default defineComponent({
  directives: {
    ClickAway: directive
  },
  components: {
    CustomButton,
    TableTile,
    CustomTable,
    NameIcon,
    customListDown,
    DatePickerModelValue,
    Swiper,
    SwiperSlide,
    Tooltip
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const projectId = +route.params.id
    const weekArray = ref<any>([])
    const weekDuration = ref(0)
    const standardWorkHours = ref(8)
    const projectStartDate = ref<number | Date | undefined>(0)
    const teamsDropDownValue = ref<number | null | undefined>(null)
    const serviceDropDownValue = ref<number | null>(null)
    const hoursDropDownValue = ref<number | null>(null)
    const workLogs = ref<projectTypes.ProjectTimeLoggingResponse>([])
    const currentServiceId = ref<number | null>(null)

    // Hour List 1-60
    const daysArray = computed(() => {
      return Array.from(new Array(60), (x, i) => i + 1).map((e) => {
        return {
          label: e,
          id: e,
          value: e
        }
      })
    })
    store.dispatch(projectActions.GET_PROJECT_TEAM, projectId)

    const activeWeek = ref({
      weekNumber: 0,
      weekDate: moment(projectStartDate.value).format('DD MMM YYYY'),
      iconStatus: false
    })

    // Fetch work log data with specific week number
    const getWorkLogs = async (weekNumber: number) => {
      workLogs.value = await authApi.getWorkLogs(projectId, weekNumber)
      workLogs.value = workLogs.value.map((e: any) => {
        return {
          ...e,
          date: new Date(e.date)
        }
      })
    }

    // Empty fields are added when we don't have data in weeks
    const addField = () => {
      const emptyField = {
        id: 0,
        projectId: 0,
        serviceId: 0,
        userId: 0,
        subcontractorId: null,
        hoursWorked: 1,
        rate: 0,
        date: new Date(activeWeek.value.weekDate),
        user: null,
        subcontractor: null,
        service: {
          id: 0,
          studioId: 0,
          name: '',
          rate: 0,
          medianFullTimeSalary: 0,
          isActive: true,
          subcontractorId: null,
          userId: null
        }
      }
      // workLogs.value = [emptyField].concat(workLogs.value)
      const newArr = workLogs.value
      newArr.unshift(emptyField)
      workLogs.value = [...newArr]
    }

    // Get the first day of each week, the active week number and the week icon
    const mapResults = async () => {
      let weekDate = ''
      const getAllWorkLogs = await authApi.getAllWorkLogs(projectId) // Fetch all Week data
      for (let index = 1; index <= weekDuration.value; index++) {
        let selected = ''
        let iconStatus = false
        if (index !== 1) {
          weekDate = moment(weekDate).day(8).format()
        } else {
          weekDate = moment(projectStartDate.value).day(1).format()
        }
        if (getAllWorkLogs) {
          getAllWorkLogs.map((worklog: any) => {
            // Check data exist in which week
            if (moment(weekDate).isSame(worklog.date, 'week') === true) {
              iconStatus = true
            }
          })
        }
        // Check which week date is same with current week
        if (moment(weekDate).isSame(new Date(), 'week') === true) {
          activeWeek.value = {
            weekNumber: index,
            weekDate: moment(weekDate).format('DD MMM YYYY'),
            iconStatus: iconStatus
          }
          selected = 'week-selected'
          await getWorkLogs(index)
          if (workLogs.value.length <= 0) {
            addField()
          }
        }
        weekArray.value.push({
          weekNumber: index,
          weekDate: moment(weekDate).format('DD MMM YYYY'),
          selected: selected,
          iconStatus: iconStatus
        })
      }
      if (activeWeek.value.weekNumber === 0) {
        weekArray.value[0].selected = 'week-selected'
        activeWeek.value.weekNumber = 1
        activeWeek.value.weekDate = weekArray.value[0].weekDate
        await getWorkLogs(1)
        if (workLogs.value.length <= 0) {
          addField()
        } else {
          activeWeek.value.iconStatus = true
        }
      }
    }
    onMounted(async () => {
      await store.dispatch(projectActions.GET_PROJECT_DETAILS, projectId)
      await store.dispatch(UserActionTypes.GET_USERS)
      await store.dispatch(studioActions.GET_SUBCONTRACTORS)

      weekDuration.value =
        store.state.project.projectDetails && store.state.project.projectDetails?.numberOfWeeks
          ? store.state.project.projectDetails?.numberOfWeeks
          : 0
      projectStartDate.value =
        store.state.project.projectDetails && store.state.project.projectDetails?.startDate
          ? store.state.project.projectDetails?.startDate
          : 0
      standardWorkHours.value =
        store.state.project.projectTeam &&
        store.state.project.projectTeam?.studio &&
        store.state.project.projectTeam?.studio.standardWorkHours
          ? store.state.project.projectTeam?.studio.standardWorkHours
          : 8
      await mapResults()
    })

    // Fetch all studio users
    const projectUsers = computed(() =>
      store.state.user.users.map((e) => {
        return {
          ...e,
          value: e.id,
          label: e.fullName
        }
      })
    )
    // Fetch all studio subcontractors
    const projectSubcontractors = computed(() =>
      store.state.studio.subContractors.map((e) => {
        return {
          ...e,
          value: e.id,
          label: e.name
        }
      })
    )
    // Combine the list of users and subcontractors
    const projectTeamMembers = computed(() => [...projectUsers.value, ...projectSubcontractors.value])

    // Fetch the project services
    const projectServices: any = computed(() => {
      return store.state.project.projectTeam?.ProjectService.map((e: any) => {
        return {
          ...e.service,
          label: e.service.name,
          value: e.service.id,
          budget: e.rate * (e.estimatedHours / standardWorkHours.value),
          rate: e.rate
        }
      })
    })
    // Fetch the spend hours
    const projectSpend: any = computed(() => {
      const workLogsArray = Array.from(
        workLogs.value.reduce(
          (m, { serviceId, hoursWorked }) => m.set(serviceId, (m.get(serviceId) || 0) + hoursWorked),
          new Map()
        ),
        ([serviceId, hoursWorked]) => ({ serviceId, hoursWorked })
      )
      const budgetSpend = store.state.project.projectTeam?.ProjectService.map((item: any) => {
        const obj = workLogsArray.find((o: any) => o.serviceId === item.serviceId)
        return { ...item, ...obj }
      })
      return budgetSpend
    })

    // Fetch the total budget value
    const totalBudget = computed(() => {
      return projectSpend.value
        ? projectSpend.value.reduce((a: any, b: any) => a + b.estimatedHours / weekArray.value.length, 0)
        : 0
    })

    // Fetch the total spend hours
    const totalSpend = computed(() => {
      return projectSpend.value
        ? projectSpend.value.reduce((a: any, b: any) => {
            if (b.hoursWorked) {
              return a + b.hoursWorked
            } else {
              return a
            }
          }, 0)
        : 0
    })

    const getMaxDate = computed(() => {
      return new Date(moment(activeWeek.value.weekDate).add(6, 'day').format())
    })
    const calculateSpend = (budget: number, spend: number) => {
      return budget - spend
    }

    // Selected project team per week
    const selectProjectTeam = (item: any, index: number) => {
      workLogs.value[index].subcontractor = null
      workLogs.value[index].user = null
      if (item.name) {
        workLogs.value[index].subcontractor = item
      } else {
        workLogs.value[index].user = item
      }

      // teamsDropDownValue.value = null
    }

    // Selected services per week
    const selectService = (item: any, index: number) => {
      currentServiceId.value = item.id
      workLogs.value[index].service = item
      workLogs.value[index].hourlyRate = item.rate
      workLogs.value[index].serviceId = item.id
    }
    // Selected spend hours per week
    const selectHours = (item: any, index: number) => {
      currentServiceId.value = item.id
      workLogs.value[index].hoursWorked = item.value
      // workLogs.value[index].user = null
    }

    const openTeamModel = (id: number | null | undefined, workLog: any) => {
      currentServiceId.value = workLog.service.id
      if (teamsDropDownValue.value !== null) {
        teamsDropDownValue.value = null
      } else {
        teamsDropDownValue.value = id
      }
    }
    const openServiceModal = (id: number | null) => {
      if (serviceDropDownValue.value !== null) {
        serviceDropDownValue.value = null
      } else {
        serviceDropDownValue.value = id
      }
    }

    const openHoursModel = (id: number | null) => {
      if (hoursDropDownValue.value !== null) {
        hoursDropDownValue.value = null
      } else {
        hoursDropDownValue.value = id
      }
    }
    const teamMembersClickAway = () => {
      teamsDropDownValue.value = null
    }

    const serviceClickAway = () => {
      serviceDropDownValue.value = null
    }

    const hoursClickAway = () => {
      hoursDropDownValue.value = null
    }

    // Toggle the week icon when the user adds or removes work log data
    async function toogleWeekIcon() {
      let iconStatus = false
      if (workLogs.value.length > 0) {
        iconStatus = true
      }
      activeWeek.value.iconStatus = iconStatus
      weekArray.value.map((week: any) => {
        if (week.weekNumber === activeWeek.value.weekNumber) {
          week.iconStatus = iconStatus
        }
      })
    }

    // Save the timesheet detail per week
    const addWorkLog = async () => {
      let validateWorkLogData = true
      if (workLogs.value.length > 0) {
        const requestData = workLogs.value.map((e: any) => {
          // Checked data exists in Service and Team member
          if (!e.service.id || (!e.user && !e.subcontractor)) {
            validateWorkLogData = false
          }
          return {
            projectId: projectId,
            serviceId: e.service.id,
            userId: e.user ? e.user.id : null,
            hoursWorked: e.hoursWorked,
            date: new Date(e.date),
            id: e.id === 0 ? undefined : e.id,
            subcontractorId: e.subcontractor ? e.subcontractor.id : null,
            hourlyRate: e.hourlyRate
          }
        })
        if (validateWorkLogData === true) {
          await authApi.updateWorkLogs(projectId, requestData)
          await toogleWeekIcon()
          store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, 'Timesheet Confirmed')
          store.dispatch(MessageActions.SET_TOASTER_TYPE, 'success')
        } else {
          store.dispatch(
            MutationTypes.SET_TOASTER_MESSAGE,
            'Please select the Service Role and Team Member in every field'
          )
          store.dispatch(MessageActions.SET_TOASTER_TYPE, 'error')
        }
      } else {
        store.dispatch(MutationTypes.SET_TOASTER_MESSAGE, 'Please add time entry first')
        store.dispatch(MessageActions.SET_TOASTER_TYPE, 'error')
      }

      store.dispatch(MessageActions.SHOW_TOASTER_MESSAGE, true)
    }
    // When user clicks on week number all details for that week are get
    async function getCurrentWeek(weekNumber: number) {
      await getWorkLogs(weekNumber)
      let iconStatus = false
      // Check data exists in that week
      if (workLogs.value.length > 0) {
        iconStatus = true
      }
      let weekDate = ''
      weekArray.value.map((week: any) => {
        week.selected = ''
        if (week.weekNumber === weekNumber) {
          week.selected = 'week-selected'
          week.iconStatus = iconStatus
          weekDate = week.weekDate
        }
      })
      activeWeek.value = {
        weekNumber: weekNumber,
        weekDate: moment(weekDate).format('DD MMM YYYY'),
        iconStatus: iconStatus
      }

      if (workLogs.value.length <= 0) {
        addField()
      }
    }

    // set active index value in slider
    const onSwiper = (swiper: any, activeIndex: number) => {
      swiper.activeIndex = activeIndex
    }

    // When user clicks on week number all details for that week are get in slider (Mobile view)
    const activeIndexChange = async (event: any) => {
      const weekNumber = event.realIndex + 1
      await getCurrentWeek(weekNumber)
    }

    // Delete the work log data
    const deleteWorkLog = async (workLogId: number) => {
      const del = await authApi.deleteWorkLog(projectId, workLogId)
      if (del) {
        const filteredArr = workLogs.value.filter((element) => {
          return element.id !== workLogId
        })
        workLogs.value = [...filteredArr]
        await toogleWeekIcon()
      }
    }

    return {
      openTeamModel,
      teamsDropDownValue,
      teamMembersClickAway,
      serviceDropDownValue,
      serviceClickAway,
      projectTeamMembers,
      selectProjectTeam,
      projectServices,
      workLogs,
      addField,
      selectService,
      openServiceModal,
      addWorkLog,
      activeWeek,
      getCurrentWeek,
      weekArray,
      activeIndexChange,
      daysArray,
      openHoursModel,
      hoursDropDownValue,
      hoursClickAway,
      selectHours,
      projectSpend,
      calculateSpend,
      totalBudget,
      totalSpend,
      deleteWorkLog,
      onSwiper,
      getMaxDate,
      standardWorkHours,
      intlFormat
    }
  }
})
</script>

<style lang="sass" scoped>
p
  margin: 0
  padding: 0
@media(min-width: $ms-breakpoint)
  .desktop-hide
    display: none
.verified
  margin-left: 0.5rem
  height: 1rem
  width: 1rem
  background-image: url('~@/assets/icons/tick-green.svg')
  background-size: 90%
  background-position: center
  background-color: transparent
  background-repeat: no-repeat
.warning
  margin-left: 0.5rem
  height: 1rem
  width: 1rem
  background-image: url('~@/assets/icons/error-mustered.svg')
  background-size: 90%
  background-position: center
  background-color: transparent
  background-repeat: no-repeat
.inactive
  opacity: 0.6
.timesheet
  .member-wrapper
    @media( max-width: $ms-breakpoint)
      padding-left: 0
  .name-wrapper
    overflow: hidden
    text-overflow: ellipsis
    max-width: 150px
    word-break: break-word
    min-width: 106px
    @media( max-width: $ms-breakpoint)
      max-width: initial
      min-width: auto
  .error-img
    width: 15px
    margin-left: 1rem
  .weeks
    display: flex
    align-items: center
    margin: 0
    overflow: auto
    min-width: 99%
    width: 100px
    .week
      display: flex
      align-items: center
  h4
    margin-bottom: 0.4rem
    margin-top: 0.6rem
  .stats
    display: flex
    justify-content: space-between
    width: 100%
    margin-top: 1rem
    @media( max-width: $ms-breakpoint)
      margin: 2rem 0
    .budget
      border-right: solid 1px map-get($greyShades,greyShade30 )
      width: 50%
    h4
      font-size: 1rem
      margin-top: 0.2rem
    p
      color: map-get($greyShades,greyShade50 )
      font-weight: normal
  .current-week
    display: grid
    grid-template-columns: 1fr 6fr 1fr
    align-items: center
    padding: 1rem 3.75rem
    .swiper-container
      width: 100%
      .swiper-button-prev, .swiper-button-next
        background-color: transparent
        svg
          fill: black
    .button
      width: max-content
      @media( max-width: $ms-breakpoint)
        margin: 0
        order: 1
        margin-top: 9px
    @media( max-width: $ms-breakpoint)
      display: flex
      flex-direction: column
      padding: 1rem 2rem
  @media( max-width: $ms-breakpoint)
    .mobile-hide
      display: none

.timesheet-table
  display: flex
  padding: 2rem
  @media(max-width: $lg-breakpoint)
    flex-direction: column-reverse
.weeks
  margin: 0
  padding: 0 3.75rem
  background-color: $white
  li
    list-style: none
    .week
      padding: 32px 24px
      &.week-selected
        background-color: $background
.timesheet-table-item
  &:first-child
    flex: 2
    flex: 0 65em
    margin-right: 1rem
    @media(max-width: $lg-breakpoint)
      flex: 2
      margin-right: 0
    @media(max-width: $ms-breakpoint)
      margin: unset
  &:last-child
    flex: 1
    flex: 0 35em
    @media(max-width: $lg-breakpoint)
      flex: 1
    @media(max-width: $lg-breakpoint)
      margin-bottom: 1rem
      width: 100%
      border: 1px solid #CFD2D7
      border-radius: 2px
      background-color: $white
      width: 100%
.tile-content
  cursor: pointer
  display: flex
  align-items: center
  justify-content: flex-start
  flex: 1
  width: 100%
  position: relative
  @media(max-width: $ms-breakpoint)
    padding: 1rem 0
    padding-left: 1rem
.tile-icon
  margin-left: 1.5rem
  margin-right: 0.5rem
  display: flex
  align-items: center
  justify-content: center
  img
    width: 1.5rem
    height: 1.5rem
  @media(max-width: $ms-breakpoint)
    // padding: 1rem 0
    padding: 0
    margin: 0
.tile-row
  display: flex
  width: 100%
  flex: 2
  & > *
    flex: 1
    width: 100%
.tableTile-wrapper
  &:not(&:last-child)
    border-bottom: 1px solid #CFD2D7
.timesheet-member
  padding-left: 1rem
  padding-right: 3rem
  margin: 0
  // border: 1px solid
  display: flex
  @media(max-width: $ms-breakpoint)
    flex-direction: column
.dropdown-wrapper
  padding-left: 0
  position: absolute
  z-index: 250
  left: 0
  top: 100%
  width: 100%
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05)
  max-height: 10rem
  overflow-y: scroll
  box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  border: 0.5px solid map-get($greyShades, 'greyShade10')
  &::-webkit-scrollbar
    width: 0
</style>
<style lang="sass">
.swiper-button-next, .swiper-button-prev
  background-color: map-get($greyShades,greyShade10 )
  border-radius: 100%
  padding: 20px
  font-size: 24px
  height: 24px
  width: 24px
  &::after
    font-size: 20px
    color: $primary
  .swiper-button-next
    margin-left: 16rem
.slider
  @media( min-width: $ms-breakpoint)
    display: none
.slider-week
  display: flex
  align-items: center
  justify-content: center
.timesheet .datepicker
  min-width: 170px
</style>
