<template>
  <div class="customDropDown">
    <ul>
      <li @click="$emit('onClick', item)" v-for="item of values" :key="item.id">{{ item.label }}</li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    values: {
      type: Array as () => { id: number; value: string | number; label: string }[],
      required: true
    }
  }
  // setup() {

  // },
})
</script>

<style lang="sass" scoped>
.customDropDown
  width: 100%
  padding: 0
  background-color: $white
  // box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  // border: 0.5px solid map-get($greyShades, 'greyShade10')
  ul
    list-style: none
    margin: 0
    padding: 0
    width: 100%
    li
      padding: 0.5rem 1rem 0.5rem 1rem
      cursor: pointer
      transition: all .2s
      width: 100%
      &:hover
        background-color: map-get($greyShades, 'greyShade10')
</style>
