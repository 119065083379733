
import { computed } from 'vue'

export default {
  name: 'CustomTable',
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any) {
    const computedClass = computed(() => {
      return props.isSmall ? 'tableContainer--small' : ''
    })

    return {
      computedClass
    }
  }
}
