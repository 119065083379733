
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    values: {
      type: Array as () => { id: number; value: string | number; label: string }[],
      required: true
    }
  }
  // setup() {

  // },
})
