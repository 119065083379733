<template>
  <table id="firstTable">
    <thead>
      <tr>
        <slot name="heading" />
      </tr>
    </thead>
    <tbody>
      <slot name="content" />
    </tbody>
  </table>
</template>

<style lang="sass" scoped>
table
  width: 100%
  border-collapse: collapse
    // border: 1px solid
  // margin: 10px 10px 0 10px
  padding: 5px
  @media(max-width: $ms-breakpoint)
    display: block
    width: 100%
    overflow-x: auto

table th
  text-align: left
  background: $white
  color: black
  padding: 25px 8px 10px 10px
  min-width: 30px
  width: 100%

table td
  text-align: left
  padding: 15px
    // border-right: 1px solid
  background: $white
  width: 100%

table tbody tr:last-child
  font-weight: bold
td.pink-text
  color: #E4536B
    // border-right: 1px solid black
td.green-text
  color: #2FC397
    // border-right: 1px solid black
</style>
