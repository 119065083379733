<template>
  <div :class="['tableContainer', computedClass]">
    <div class="tableWrapper">
      <table class="table-styled">
        <thead>
          <slot name="tablehead" />
        </thead>
        <tbody>
          <slot name="tablebody" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'CustomTable',
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any) {
    const computedClass = computed(() => {
      return props.isSmall ? 'tableContainer--small' : ''
    })

    return {
      computedClass
    }
  }
}
</script>

<style lang="sass" scoped>
.tableContainer
    overflow: visible
    width: 100%
    display: inline-block
    margin-bottom: .5rem
.tableWrapper
    overflow-x: auto
    display: inline-block
    float: left
    width: calc( 100% + 2rem )
    margin: 0 -1rem
table.table-styled::v-deep
    width: 100%
    table-layout: fixed
    min-width: 540px
    border-color: transparent
    vertical-align: top
    text-align: left
    border-collapse: collapse
    thead tr
      border-bottom: 1px solid #ddd
    tbody tr:not(:only-child):not(:last-child)
      border-bottom: 1px solid #ddd
    td,th
      padding: 6px
      word-wrap: break-word
      white-space: normal
      background-color: transparent
      border: none
      vertical-align: top
      font-size: 14px
      @media all and (min-width: $md-breakpoint)
        font-size: 16px
    td:first-child, th:first-child
      padding-left: 1rem
    td:last-child, th:last-child
      padding-right: 1rem
    td:nth-child(2):not(:last-child), th:nth-child(2):not(:last-child)
      padding-right: 1.75rem

.tableContainer--small
    table.table-styled
        min-width: 100%
</style>
